<template>
  <div>
    <Card class="py-2">
      <b-row>
        <b-col>
          <label>Nome da Campanha</label>
          <b-input v-model="model.description"></b-input>
        </b-col>
        <b-col>
          <label>Data de Envio</label>
          <b-input v-model="model.send_date"></b-input>
        </b-col>
        <b-col>
          <label>Assunto</label>
          <b-input v-model="model.subject"></b-input>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <label>Canal</label>
          <b-form-select
            :options="selectOptionsChannels"
            v-model="model.channel_id"
          ></b-form-select>
        </b-col>
        <b-col>
          <label>Base de Dados</label>
          <b-form-select
            @change="selected_file($event)"
            :options="selectOptionsFiles"
            v-model="model.campaign_file_id"
          ></b-form-select>
        </b-col>
        <b-col>
          <template v-if="model.campaign_file_id">
            <label>Modelo</label>
            <b-form-select
              @change="selected_model($event)"
              :options="selectOptionsCampaignModels"
              v-model="model.communication_templates_customs_id"
            ></b-form-select>
          </template>
        </b-col>
      </b-row>
    </Card>
    <Card :title="'Dados do Arquivo'" class="mt-2" v-if="selectedFile">
      <TableFileData :fileData="selectedFile" />
    </Card>
    <b-row class="mt-2">
      <b-col md="4">
        <Card
          class="pb-3"
          title="Variaveis Encontradas no Modelo"
          v-if="variables.length > 0"
        >
          <b-row
            class="mt-3"
            v-for="(variable, index) in variables"
            :key="index"
          >
            <b-col>
              <strong class="mr-2">Variável:</strong><span>{{ variable }}</span>
              <b-form-select
                :options="selectOptionsFileHeaders"
                @change="
                  change_model_variable({ original: variable, new: $event })
                "
              ></b-form-select>
            </b-col>
          </b-row>
          <hr />
          <div class="d-flex justify-content-end">
            <b-button @click="save_campaign">Botão</b-button>
          </div>
        </Card>
      </b-col>
      <b-col>
        <Card v-if="model.communication_templates_customs_id" class="pb-3">
          <b-row class="pt-3">
            <b-col>
              <small class="text-muted"
                >Modelo Selecionado (Pré visualização)</small
              >
            </b-col>
            <b-col class="d-flex justify-content-end">
              <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
                <b-form-radio-group
                  v-model="previewType"
                  :aria-describedby="ariaDescribedby"
                  name="radio-sub-component"
                >
                  <b-form-radio value="tag"
                    ><small class="text-muted">Mostrar Tag</small></b-form-radio
                  >
                  <b-form-radio value="data"
                    ><small class="text-muted"
                      >Mostrar Dados</small
                    ></b-form-radio
                  >
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <iframe
            :srcdoc="tempHtml"
            frameborder="0"
            style="width: 100%; height: 50vh; border: 1px solid lightgray"
          ></iframe>
        </Card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      generatedFiles: [],
      previewType: "tag",
      originalHtml: "",
      tempHtml: "",
      variables: [],
      fileHeaders: [],
      changes: [],
      selectedFile: "",
      model: {
        application_id: null,
        unity_id: this.$store.state.auth.currentUser.unity_id,
        channel_id: "",
        campaign_file_id: "",
        communication_templates_customs_id: "",
        description: "",
        subject: "",
        send_date: "",
        total: "",
        status: "sent",
        active: "1",
      },
    };
  },
  components: {
    TableFileData: () => import("./components/TableFileData.vue"),
  },
  watch: {
    previewType: {
      handler() {
        this.change_model_tags();
      },
    },
  },
  computed: {
    selectOptionsFileHeaders() {
      return _.map(this.fileHeaders, (item) => {
        return { text: item, value: item };
      });
    },
  },
  methods: {
    selected_model(id) {
      const selectedModel = this.campaignModelsById[id];
      this.originalHtml = selectedModel.html;
      this.tempHtml = selectedModel.html;
      this.get_model_variables(selectedModel.html);
    },
    get_model_variables(html) {
      const variables = this.get_variables(html);
      this.variables = variables;
    },
    selected_file(id) {
      this.selectedFile = this.filesById[id];
      const file = JSON.parse(this.selectedFile.file)[0];
      const headers = Object.keys(file);
      this.fileHeaders = headers;
    },
    change_model_variable(data) {
      const index = _.findIndex(this.changes, (item) => {
        return item.original == data.original;
      });
      if (index !== -1) {
        this.changes.splice(index, 1, data);
      } else {
        this.changes.push(data);
      }
      this.change_model_tags();
    },
    change_model_tags() {
      this.tempHtml = this.originalHtml;
      const original = _.keyBy(this.changes, "original");

      _.forEach(this.variables, (tag) => {
        try {
          const newTag = original[tag].new;
          if (this.previewType == "tag") {
            this.tempHtml = this.tempHtml.replace(
              `{{${tag}}}`,
              `{{${newTag}}}`
            );
          } else {
            const file = JSON.parse(this.selectedFile.file)[0];
            const data = file[newTag];
            this.tempHtml = this.tempHtml.replace(`{{${tag}}}`, `${data}`);
          }
        } catch (e) {
          console.log(e);
        }
      });
    },
    save_campaign() {
      const files = JSON.parse(this.selectedFile.file);
      const original = _.keyBy(this.changes, "original");
      _.forEach(files, (file) => {
        this.tempHtml = this.originalHtml;
        _.forEach(this.variables, (tag) => {
          try {
            const newTag = original[tag].new;
            const data = file[newTag];
            this.tempHtml = this.tempHtml.replace(`{{${tag}}}`, `${data}`);
          } catch (e) {
            
          }
        });
        
        
        
        
      });
      
    },
  },
};
</script>

<style>
</style>